import { useParams } from "react-router-dom";
import "../stylesheets/home.scss";
import "../stylesheets/plato.scss";
import React, { useEffect, useState } from "react";
import { SSO_URL, GET_DOCUMENT_URL } from "../constants/api-urls";

function PlatoContenidoBtns({documentUrl, infographyUrl, type, handleViewed}) {
  const {id} = useParams();
  const isCourse = type == "Curso"
  const isTraining = type == "Formación Intensiva"
  const isInteractive = type == "Caso Clínico Interactivo"
  const isInfography = !!infographyUrl

  const [specialUrl, setSpecialUrl] = useState();

  useEffect(() => {
    if (id != 59) {
      setSpecialUrl(documentUrl);
      return;
    }
    fetch(SSO_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network error.");
      }
      return response.json();
    })
    .then((data) => {
      setSpecialUrl(data);
    })
    .catch((err) => {
      console.log("Connection error: ", err);
    });
  },[]);


  function handleGetDocument(){
    // Si es una infografía, realiza una solicitud a la API específica para obtener el PDF
    fetch(`${GET_DOCUMENT_URL}?resource_id=${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network error.");
        }
        return response.blob(); // Obtén el PDF como un Blob
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob); // Crea una URL temporal para el Blob
        window.open(url, '_blank'); // Abre la URL en una nueva pestaña
        URL.revokeObjectURL(url); // Libera la URL cuando ya no la necesites
      })
      .catch((err) => {
        console.log("Connection error: ", err);
      });
  };
  

  return (
    <div className="btns_infografia">
      {/* Infographies */}
      {isInfography && documentUrl && <a onClick={handleViewed} target="_blank" href={documentUrl} class="btn btn-secondary btn_platocontenido pt-74">Ver artículo</a>}
      {isInfography && <btn onClick={handleGetDocument} target="_blank" href={infographyUrl} class="btn btn-main btn_platocontenido">Ver infografía</btn>}
      {/* Courses */}
      {isCourse && <a onClick={handleViewed} target="_blank" href={specialUrl} class="btn btn-main btn_platocontenido">Acceder al curso</a>}
      {/* Training */}
      {isTraining && <a onClick={handleViewed} target="_blank" href={documentUrl} class="btn btn-main btn_platocontenido">Acceder a la formación</a>}
      {/* Interactive */}
      {isInteractive && <a onClick={handleViewed} target="_blank" href={documentUrl} class="btn btn-main btn_platocontenido">Acceder al Caso Clínico Interactivo</a>}
    </div>
  );
}

export default PlatoContenidoBtns;
