import Dropdown from "react-bootstrap/Dropdown";
import "../stylesheets/dropdownactions.scss";
import CambiarClave from "../components/CambiarClave";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import React, { useState } from "react";

function DropdownActions() {
  const [showCambiarClaveModal, setShowCambiarClaveModal] = useState(false);

  const openCambiarClaveModal = () => {
    setShowCambiarClaveModal(true);
  };

  const closeCambiarClaveModal = () => {
    setShowCambiarClaveModal(false);
  };

  return (
    <>
      <CambiarClave
        show={showCambiarClaveModal}
        onClose={closeCambiarClaveModal}
      />
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-actions">
          <MoreHorizIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-3dots">
          <div className="menu-item acciones px-2">
            <p className="menu-content fs-7">ACCIONES</p>
          </div>
          <div className="menu-item dropdown-item px-2">
            <Dropdown.Item className="menu-link fw-bold" href="/perfil/edit">
              Editar perfil
            </Dropdown.Item>
          </div>
          <div className="menu-item dropdown-item px-2">
            <Dropdown.Item
              className="menu-link fw-bold"
              onClick={openCambiarClaveModal}
            >
              Cambiar clave
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
export default DropdownActions;
