import React from 'react';
import { Button} from 'antd';

const EventsCalendarButton = ({ onClickFunc, event, type, icon, name }) => {
    return (
        <Button
        id={event.id}
        target="_blank"
        className="event-button bg-transparent shadow-none text-start text-decoration-none border-0 rounded-0"
        style={{ padding: '5px 20px', minHeight: '50px'}}
        rel="noopener noreferrer"
        onClick={onClickFunc(type, event)}
        >
        <img src={icon} className="pe-2" style={{ height: '24px' }} alt="Calendar" />            
        {name}
        </Button> 
    );
  };

export default EventsCalendarButton;