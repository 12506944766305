import { useEffect, useState } from "react";
import { Medal } from "./Medal";
import { GET_ACHIEVEMENTS_URL, RESET_ACHIEVEMENTS_URL } from "../constants/api-urls";

export function MedalsProfile() {
  const [medals, setMedals] = useState([]);

  useEffect(() => {
    fetch(`${GET_ACHIEVEMENTS_URL}?amount=6`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network error.");
      }
      return response.json();
    })
    .then((data) => {
      setMedals(data);
    })
    .catch((err) => {
      console.log("Connection error: ", err);
    });
  }, []);

  const handleResetMedals = async () => {
    try {
      const response = await fetch(RESET_ACHIEVEMENTS_URL, {        
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setMedals(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (  
    <>
    
      {Array.isArray(medals) && medals.length > 0 ? (
        <>
          {medals.map((medal) => (
            <Medal key={medal.id} title={medal.title} medal={medal.id} />
          ))}
          <br></br>
          <div className="cta-logros">
                  <a className="btn-logros" href="/logros">
                    Ver todos los logros
                  </a>
          </div>
        </>
      ) : (
        <p>No hay logros disponibles</p>
      )}    
      </>  
  );
}
