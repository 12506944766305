import React, { useEffect, useState } from 'react';
import "../stylesheets/profile.scss";
import "../stylesheets/profileedit.scss";
import Result from "../components/Result";
import Pagination from "../components/Pagination";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ArrowRight from "@mui/icons-material/ArrowForwardIos";
import logo_moc from "../images/restaurantes/logo_moc_breadcrumb.png";
import { Helmet } from "react-helmet";
import { BASE_URL } from "../constants/api-urls.js";
import ProfileHeader from '../components/ProfileHeader.jsx';
import { MedalsProfile } from '../components/MedalsProfile.jsx';

export const Profile = ({ pageTitle }) => {
  const user = JSON.parse(window.localStorage.getItem('user'));

  const [bookmarks, setBookmarks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showMedalsSection, setShowMedalsSection] = useState(true); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${BASE_URL}bookmarks/getUserBookmarks?user_id=${user.id}`, {
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setBookmarks(data);
        setTotalPages(Math.ceil(data.length / PAGE_SIZE));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const PAGE_SIZE = 10;
  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = Math.min(startIndex + PAGE_SIZE, bookmarks.length);



  // Función para eliminar un marcador de la lista localmente
  const removeBookmarkFromList = (resourceId) => {
    setBookmarks(bookmarks.filter(bookmark => bookmark.digital_entity_id !== resourceId));
  };

  // Función para alternar la visibilidad de la sección de medallas
  const toggleMedalsSection = () => {
    setShowMedalsSection(!showMedalsSection);
  };

  return (
    <>
      <div>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      </div>
      <div className="toolbar-contact">
        <div className="background_toolbar"></div>
      </div>
      <Container id="contact_container">
        <Row className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-6">
          <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-6 my-0 pt-1">
            <li className="breadcrumb-item text-white opacity-85">
              <a className="">
                <img
                  src={logo_moc}
                  className="img-moc-breadcrumb"
                  alt="Imagen calendario de eventos"
                />
              </a>
            </li>
            <li className="text-white opacity-85 mt-6 mx-3">
              <i className="arrow-breadcrumb">
                <ArrowRight></ArrowRight>
              </i>
            </li>
            <li className="breadcrumb-item breadcrumb-title text-white opacity-85 mt-6">
              Perfil
            </li>
          </ul>
        </Row>
        <Row id="profile" className="row">
          <ProfileHeader user={user} />
          <div className="card mb-6">
            <div className="card-header">
              <div className="bio-curriculum">
                <div className="card-title fw-bold titulo-biografia">
                  Bio - Currículum
                </div>
              </div>
            </div>
            <div className="ql-editor texto-biografia">
              {user.biography || <span className='text-gray-500'>Aún no has añadido ninguna biografía</span>}
            </div>
          </div>
          <div className="card mb-6">
            <div className="card-header">
              <div className="bio-curriculum">
                <div className="card-title fw-bold titulo-biografia">
                  Medallas y logros
                </div>
              </div>
              <button className="btn btn-toggle-section button-show-section" onClick={toggleMedalsSection} >
              {showMedalsSection ? 'Ocultar sección' : 'Mostrar sección'}
              </button>
            </div>
            {showMedalsSection && (
              <div className="card-body texto-biografia row">
                <MedalsProfile/>
              </div>
            )}
          </div>
          <div className="card mb-6">
            <div className="card-header">
              <div className="bio-curriculum">
                <div className="card-title fw-bold titulo-biografia">
                  Platos favoritos
                </div>
              </div>
            </div>
            <div className="mt-10">
              {bookmarks.slice(startIndex, endIndex).map(bookmark => (
                <Result
                  key={bookmark.id} 
                  title={bookmark.title}
                  description={bookmark.description}
                  duration={bookmark.video_duration}
                  resource_id={bookmark.digital_entity_id}
                  isFavorite={true}
                  removeBookmarkFromList={removeBookmarkFromList}
                  parent={bookmark.digital_entity.parent_id}                   
                />
              ))}
            </div>
            <div className="d-flex align-items-center justify-content-center">
                <div className="pagination">
                  <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
                </div>
              </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
