import React, { useEffect, useState } from "react";
import "../stylesheets/events.scss";
import { BASE_URL, SEND_SAVED_EVENT } from "../constants/api-urls";
import { format } from "date-fns";
import esLocale from "date-fns/locale/es";
import calendar_home from "../images/events/calendar-home.png";
import ArrowLeft from "@mui/icons-material/ArrowBackIosNew";
import ArrowRight from "@mui/icons-material/ArrowForwardIos";
import { Button, Dropdown } from "antd";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import icon_g_calendar from "../images/events/icons/g-calendar.svg";
import icon_add_calendar from "../images/events/icons/add-calendar.png";
import icon_add_outlook from "../images/events/icons/icons8-outlook-calendar.svg";
import icon_add_teams from "../images/events/icons/icons8-teams.svg";
import icon_add_365 from "../images/events/icons/icons8-office-365.svg";
import icon_add_apple from "../images/events/icons/icons8-apple-logo.svg";
import EventsCalendarButton from "./EventsCalendarButton";

function Events() {
  const [events, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    fetchEvents(currentDate);
  }, [currentDate]); // Trigger fetchEvents whenever currentDate changes

  const fetchEvents = (date) => {
    // Account for timezone offset
    const offset = date.getTimezoneOffset(); // Get the timezone offset in minutes
    const fromDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      1,
      0,
      -offset
    );
    const nextMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      1,
      0,
      -offset
    );
    const toDate = new Date(nextMonth - 1);

    const url = `${BASE_URL}events/getEvents?from=${fromDate.toISOString()}&to=${toDate.toISOString()}`;
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network error.");
        }
        return response.json();
      })
      .then((data) => {
        setEvents(data);
      })
      .catch((err) => {
        console.log("Connection error: ", err);
      });
  };

  const handlePrevMonth = () => {
    const prevMonth = new Date(currentDate);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    setCurrentDate(prevMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(currentDate);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setCurrentDate(nextMonth);
  };

  const handleAddCalendarClick = (calendarType, id) => {
    return () => {
      addCalendar(calendarType, id);
    };
  };

    const addCalendar = (calendarType, event) => {
      // Depending on the calendarType, perform the appropriate action
      switch (calendarType) {
        case 'ical':
          downloadICS(event);          
          break;
        case 'google':
          addToGoogleCalendar(event);
          break;
        case 'outlook':
          addToOutlookCalendar(event);
          break;
        case 'teams':
          addToMicrosoftTeams(event);
          break;
        case '365':
          addToMicrosoft365(event);
          break;
        case 'apple':
          addToAppleCalendar(event);
          break;
        default:
          alert("Invalid calendar type.");
      }
      eventAddedAPI();
    };

    const eventAddedAPI = () => {
      fetch(SEND_SAVED_EVENT, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network error.");
        }
      })
      .catch((err) => {
        console.log("Connection error: ", err);
      });
    }

  const downloadICS = (event) => {
    const icsContent = generateICSContent(event);

    // Create a blob from the icsContent
    const blob = new Blob([icsContent], { type: "text/calendar" });

    // Create a temporary anchor element and set its href attribute to the URL of the blob
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);

    // Set the filename of the download
    link.download = "event-to-save-in-my-calendar.ics";

    // Programmatically click the anchor element to initiate the download
    link.click();
  };

  const generateICSContent = (event) => {
    const formattedDate = new Date(event.date)
      .toISOString()
      .replace(/-/g, "")
      .slice(0, 8);

    return `BEGIN:VCALENDAR
    VERSION:2.0
    BEGIN:VEVENT
    UID:${event.id}
    DTSTAMP:${formattedDate}T000000Z
    DTSTART:${formattedDate}T000000Z
    SUMMARY:${event.name}
    DESCRIPTION:${event.description}
    LOCATION:${event.location || ""}
    END:VEVENT
    END:VCALENDAR`;
  };

  const addToGoogleCalendar = (event) => {
    // Split the event schedule into start and end times
    const [startTime, endTime] = event.schedule.split(" - ");

    // Parse start time
    const [startHour, startMinute] = startTime.split(":").map(Number);

    // Parse end time
    const [endHour, endMinute] = endTime.split(":").map(Number);

    // Create start date object and set hours and minutes
    const startDate = new Date(event.date);
    startDate.setHours(startHour, startMinute);

    // Create end date object and set hours and minutes
    const endDate = new Date(event.date);
    endDate.setHours(endHour, endMinute);

    // Format dates in the local timezone
    const formatDate = (date) => {
      return `${date.getFullYear()}${padZero(date.getMonth() + 1)}${padZero(
        date.getDate()
      )}T${padZero(date.getHours())}${padZero(date.getMinutes())}00`;
    };

    const padZero = (num) => {
      return num < 10 ? "0" + num : num;
    };

    // Construct Google Calendar URL
    const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      event.name
    )}&dates=${formatDate(startDate)}/${formatDate(
      endDate
    )}&details=${encodeURIComponent(
      event.description
    )}&location=${encodeURIComponent(event.location)}`;

    // Open the Google Calendar event creation page in a new tab
    window.open(googleCalendarUrl, "_blank");
  };

  const addToOutlookCalendar = (event) => {
    // Split the event schedule into start and end times
    const [startTime, endTime] = event.schedule.split(" - ");

    // Parse start time
    const [startHour, startMinute] = startTime.split(":").map(Number);

    // Parse end time
    const [endHour, endMinute] = endTime.split(":").map(Number);

    // Create start date object and set hours and minutes
    const startDate = new Date(event.date);
    startDate.setHours(startHour, startMinute);

    // Create end date object and set hours and minutes
    const endDate = new Date(event.date);
    endDate.setHours(endHour, endMinute);

    // Format dates in the local timezone
    const formatDate = (date) => {
      return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
        date.getDate()
      )}T${padZero(date.getHours())}:${padZero(date.getMinutes())}:00`;
    };

    const padZero = (num) => {
      return num < 10 ? "0" + num : num;
    };

    // Encode HTML content
    const encodedDescription = encodeURIComponent(event.description);
    const encodedSubject = encodeURIComponent(event.name);

    // Construct Outlook Calendar URL with properly encoded parameters
    const outlookCalendarUrl = `https://outlook.live.com/owa/?path=/calendar/action/compose&subject=${encodedSubject}&body=${encodedDescription}&startdt=${formatDate(
      startDate
    )}&enddt=${formatDate(endDate)}`;

    // Open the Outlook Calendar event creation page in a new tab
    window.open(outlookCalendarUrl, "_blank");
  };

  const addToMicrosoftTeams = (event) => {
    // Split the event schedule into start and end times
    const [startTime, endTime] = event.schedule.split(" - ");

    // Parse start time
    const [startHour, startMinute] = startTime.split(":").map(Number);

    // Parse end time
    const [endHour, endMinute] = endTime.split(":").map(Number);

    // Create start date object and set hours and minutes
    const startDate = new Date(event.date);
    startDate.setHours(startHour, startMinute);

    // Create end date object and set hours and minutes
    const endDate = new Date(event.date);
    endDate.setHours(endHour, endMinute);

    // Format dates in the local timezone
    const formatDate = (date) => {
      return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
        date.getDate()
      )}T${padZero(date.getHours())}:${padZero(date.getMinutes())}:00`;
    };

    const padZero = (num) => {
      return num < 10 ? "0" + num : num;
    };

    // Encode HTML content
    const encodedDescription = encodeURIComponent(
      JSON.stringify({ content: event.description })
    );

    // Construct event details
    const eventDetails = {
      subject: event.name,
      location: event.location,
      content: encodedDescription,
      startTime: formatDate(startDate),
      endTime: formatDate(endDate),
    };

    // Encode event content as URI component
    const decodedContent = decodeURIComponent(eventDetails.content);
    const eventContent = JSON.parse(decodedContent).content;

    // Encode event details as URI component
    const encodedEventDetails = encodeURIComponent(
      JSON.stringify(eventDetails)
    );

    // Microsoft Teams deep link URL
    const teamsUrl = `https://teams.microsoft.com/l/meeting/new?subject=${encodeURIComponent(
      event.name
    )}&attendees=&startTime=${formatDate(startDate)}&endTime=${formatDate(
      endDate
    )}&location=${encodeURIComponent(event.location)}&content=${eventContent}`;

    // Open the Microsoft Teams event creation page in a new tab
    window.open(teamsUrl, "_blank");
  };

  const addToMicrosoft365 = (event) => {
    // Split the event schedule into start and end times
    const [startTime, endTime] = event.schedule.split(" - ");

    // Parse start time
    const [startHour, startMinute] = startTime.split(":").map(Number);

    // Parse end time
    const [endHour, endMinute] = endTime.split(":").map(Number);

    // Create start date object and set hours and minutes
    const startDate = new Date(event.date);
    startDate.setHours(startHour, startMinute);

    // Create end date object and set hours and minutes
    const endDate = new Date(event.date);
    endDate.setHours(endHour, endMinute);

    // Format dates in the local timezone
    const formatDate = (date) => {
      return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
        date.getDate()
      )}T${padZero(date.getHours())}:${padZero(date.getMinutes())}:00`;
    };

    const padZero = (num) => {
      return num < 10 ? "0" + num : num;
    };

    // Encode HTML content
    const encodedDescription = encodeURIComponent(
      JSON.stringify({ content: event.description })
    );

    // Construct event details
    const eventDetails = {
      subject: event.name,
      content: encodedDescription,
      startdt: formatDate(startDate),
      enddt: formatDate(endDate),
    };

    // Encode each component separately
    const encodedSubject = encodeURIComponent(eventDetails.subject);
    const encodedStartTime = encodeURIComponent(eventDetails.startdt);
    const encodedEndTime = encodeURIComponent(eventDetails.enddt);

    // Encode event content as URI component
    const decodedContent = decodeURIComponent(eventDetails.content);
    const eventContent = JSON.parse(decodedContent).content;

    // Construct Microsoft 365 deep link URL
    const m365Url = `https://outlook.office.com/calendar/action/compose?rru=addevent&startdt=${encodedStartTime}&enddt=${encodedEndTime}&subject=${encodedSubject}&body=${eventContent}`;

    // Open the Microsoft 365 event creation page in a new tab
    window.open(m365Url, "_blank");
  };

  const addToAppleCalendar = (event) => {
    // Split the event schedule into start and end times
    const [startTime, endTime] = event.schedule.split(" - ");

    // Parse start time
    const [startHour, startMinute] = startTime.split(":").map(Number);

    // Parse end time
    const [endHour, endMinute] = endTime.split(":").map(Number);

    // Create start date object and set hours and minutes
    const startDate = new Date(event.date);
    startDate.setHours(startHour, startMinute);

    // Create end date object and set hours and minutes
    const endDate = new Date(event.date);
    endDate.setHours(endHour, endMinute);

    // Format dates in the local timezone
    const formatDate = (date) => {
      return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
        date.getDate()
      )}T${padZero(date.getHours())}:${padZero(date.getMinutes())}:00`;
    };

    const padZero = (num) => {
      return num < 10 ? "0" + num : num;
    };

    // Encode HTML content
    const encodedDescription = encodeURIComponent(event.description);

    // Construct iCalendar data
    const calendarData = `BEGIN:VCALENDAR
    VERSION:2.0
    BEGIN:VEVENT
    SUMMARY:${event.name}
    DESCRIPTION:${encodedDescription}
    DTSTART:${formatDate(startDate)}
    DTEND:${formatDate(endDate)}
    END:VEVENT
    END:VCALENDAR`;

    // Create a Blob containing the calendar data
    const blob = new Blob([calendarData], {
      type: "text/calendar;charset=utf-8",
    });

    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute(
      "download",
      "apple-event-to-save-in-my-calendar.ics"
    );

    // Append the link to the body and trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="me-lg-5">
      <div className="sub-card-agenda mb-10 p-10 customheight preanchor overflow-hidden">
        <h1 className="anchor mb-5 section-title text-violet">
          Agenda de eventos
        </h1>
        <div className="mes my-5">
          <div className="text-center">
            <Button
              className="btn btn-icon text-violet"
              onClick={handlePrevMonth}
            >
              <i className="fs-1 mr-0 mb-0 d-flex align-items-center">
                <ArrowLeft></ArrowLeft>
              </i>
            </Button>
            <h3 className="d-inline m-5 date-calendar text-capitalize">
              <strong>
                {format(currentDate, "MMMM yyyy", { locale: esLocale })}
              </strong>
            </h3>
            <Button
              className="btn btn-icon text-violet"
              onClick={handleNextMonth}
            >
              <i className="fs-1 mr-0 mb-0 d-flex align-items-center">
                <ArrowRight></ArrowRight>
              </i>
            </Button>
          </div>
        </div>
        <div className="body-calendar" style={{ maxHeight: "70%" }}>
          {events.length > 0 ? (
            events.map((evento) => (
              <div
                key={evento.id}
                className="calendar-description my-10 mx-auto mb-4 row w-100"
                style={{ borderLeft: "3px solid #9b83d8", paddingLeft: "30px" }}
              >
                <div className="col-md-8 mb-3 text-start" key={evento.id}>
                  <strong>{evento.name}</strong>
                  <br />
                  <span
                    dangerouslySetInnerHTML={{ __html: evento.description }}
                  />
                  <br />
                  <em>
                    {format(new Date(evento.date), "EEEE d 'de' MMMM", {
                      locale: esLocale,
                    })}{" "}
                    - {evento.schedule}
                  </em>
                </div>
                <div className="col-md-4 d-flex m-auto justify-content-end">
                  <Dropdown
                    placement="top"
                    overlay={
                      <div
                        className="d-flex"
                        style={{
                          flexDirection: "column",
                          textAlign: "left",
                          minWidth: "200px",
                          background: "#f5f5f5",
                          borderRadius: "6px",
                          boxShadow:
                            "rgba(0 0 0 / 60%) 3px 6px 40px -5px, rgba(0 0 0 / 60%) 3px 3px 15px -4px",
                        }}
                      >
                        <EventsCalendarButton
                          onClickFunc={handleAddCalendarClick}
                          event={evento}
                          type="ical"
                          icon={icon_add_calendar}
                          name="iCal"
                        />
                        <EventsCalendarButton
                          onClickFunc={handleAddCalendarClick}
                          event={evento}
                          type="google"
                          icon={icon_g_calendar}
                          name="Google"
                        />
                        <EventsCalendarButton
                          onClickFunc={handleAddCalendarClick}
                          event={evento}
                          type="outlook"
                          icon={icon_add_outlook}
                          name="Outlook"
                        />
                        <EventsCalendarButton
                          onClickFunc={handleAddCalendarClick}
                          event={evento}
                          type="teams"
                          icon={icon_add_teams}
                          name="Microsoft Teams"
                        />
                        <EventsCalendarButton
                          onClickFunc={handleAddCalendarClick}
                          event={evento}
                          type="365"
                          icon={icon_add_365}
                          name="Microsoft 365"
                        />
                        <EventsCalendarButton
                          onClickFunc={handleAddCalendarClick}
                          event={evento}
                          type="apple"
                          icon={icon_add_apple}
                          name="Apple"
                        />
                      </div>
                    }
                  >
                    <Button
                      id={evento.id}
                      key={evento.id}
                      className="p-0"
                      style={{
                        background: "#9b83d8",
                        width: "48px",
                        height: "48px",
                      }}
                    >
                      <CalendarMonthOutlinedIcon className="text-white" />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            ))
          ) : (
            <div>
              <div className="mb-5">
                <div>
                  <img
                    src={calendar_home}
                    className="img-calendar"
                    alt="Imagen calendario de eventos"
                  />
                </div>
              </div>
              <div className="description my-10 w-75 mx-auto text-violet">
                <p>
                  ¡Vaya!, parece que no hay próximos eventos en la agenda para
                  este mes
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Events;
