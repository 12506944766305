import React, { useEffect, useState } from "react";
import "../stylesheets/contact.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CheckboxPrivacidad from "../components/CheckboxPrivacidad";
import contact from "../images/contacto/img_contact.png";
import { Helmet } from "react-helmet";
import SubmitForm from "../components/SubmitForm";
import { CONTACT_US_URL } from "../constants/api-urls";

export const Contact = ({ pageTitle }) => {
  const input1 = "input-1";
  const input2 = "input-2";
  const input3 = "input-3";
  const input4 = "input-4";

  const [enabledForm, setEnabledForm] = useState(false);
  const [typeConsulting, setTypeConsulting] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const [accepted, setAccepted] = useState(false);
  const [submitBtnText, setSubmitBtnText] = useState("Enviar");

  useEffect(() => {
    const isEnabled = !!typeConsulting && !!subject && !!message && accepted;
    setEnabledForm(isEnabled);
    setSubmitBtnText("Enviar");
  }, [typeConsulting, subject, message, accepted]);

  function changeTypeConsulting(event) {
    setTypeConsulting(event.target.value);
  }

  function changeSubject(event) {
    setSubject(event.target.value);
  }

  function changeMessage(event) {
    setMessage(event.target.value);
  }

  function isAccepted(event) {
    setAccepted(event.target.checked);
    if (event.target.checked) {
      document.getElementById(input4).setAttribute("hidden", "");
    } else {
      document.getElementById(input4).removeAttribute("hidden");
    }
  }

  function submit(event) {
    event.preventDefault();
    setSubmitBtnText("Enviando mensaje...");
    setEnabledForm(false);

    const params = {
      type_message: typeConsulting,
      subject: subject,
      message: message,
      accept_conditions: accepted,
    };

    fetch(CONTACT_US_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (!response.ok) {
          setSubmitBtnText("Ha sucedido un error");
          throw new Error("Network error.");
        }
        setTimeout(() => {
          setSubmitBtnText("Mensaje enviado");
        }, 500);
        return response.json();
      })
      .catch((err) => {
        console.log("Connection error: ", err);
      });
  }

  function adviceIfEmpty(inputName, event) {
    if (!event.target.value) {
      document.getElementById(inputName).removeAttribute("hidden");
    } else {
      document.getElementById(inputName).setAttribute("hidden", "");
    }
  }

  return (
    <>
      <div>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      </div>
      <div className="toolbar-contact">
        <div className="background_toolbar"></div>
      </div>
      <Container id="contact_container">
        <Row className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-6">     
            <div className="breadcrumb-item breadcrumb-title text-white">
              Contacto con la plataforma
            </div>
        </Row>
        <Row id="contact" className="row">
          <Col sm={6} className="p-0">
            <div className="me-lg-5">
              <div className="sub-card-contact bg-light mb-10 customheight preanchor">
                <h1 className="anchor mb-5 section-title text-violet">
                  Contacta con MOC
                </h1>
                <div className="mb-22 description text-violet">
                  <b>Si en algún momento tienes alguna duda o problema</b> con
                  respecto al uso de la plataforma, no dudes en contactar con
                  nuestro equipo de MOC.
                </div>
                <form onSubmit={submit}>
                  <div className="mb-8">
                    <label class="textfield-contact text-violet">
                      Tipo de consulta*
                    </label>
                    <select
                      className="form-control"
                      placeholder="Tipo de consulta"
                      onChange={(e) => {
                        changeTypeConsulting(e);
                        adviceIfEmpty(input1, e);
                      }}
                      onBlur={(e) => adviceIfEmpty(input1, e)}
                      required
                    >
                      <option
                        disabled="true"
                        label="Tipo de consulta"
                        selected="true"
                      ></option>
                      <option
                        label="Consulta sobre la plataforma de formación"
                        value="Consulta sobre la plataforma de formación"
                      ></option>
                      <option
                        label="Consulta de carácter médico-científico"
                        value="Consulta de carácter médico-científico"
                      ></option>
                    </select>
                    <p hidden className="required-advice" id={input1}>
                      Tipo de consulta es un campo obligatorio
                    </p>
                  </div>

                  <div className="mb-8">
                    <label class="textfield-contact text-violet">Asunto*</label>
                    <input
                      className="form-control"
                      placeholder="Asunto del mensaje"
                      name="asunto"
                      onChange={(e) => {
                        changeSubject(e);
                        adviceIfEmpty(input2, e);
                      }}
                      onBlur={(e) => adviceIfEmpty(input2, e)}
                      required
                    ></input>
                    <p hidden className="required-advice" id={input2}>
                      Asunto es un campo obligatorio
                    </p>
                  </div>
                  <div className="mb-8">
                    <label class="textfield-contact text-violet">
                      Mensaje*
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Describa su petición..."
                      name="problem"
                      onChange={(e) => {
                        changeMessage(e);
                        adviceIfEmpty(input3, e);
                      }}
                      onBlur={(e) => adviceIfEmpty(input3, e)}
                      required
                    ></textarea>
                    <p hidden className="required-advice" id={input3}>
                      Descripción es un campo obligatorio
                    </p>
                  </div>
                  <div className="">
                    <CheckboxPrivacidad
                      getCheck={(event) => {
                        isAccepted(event);
                      }}
                    />
                    <p hidden className="required-advice" id={input4}>
                      Términos y condiciones es un campo obligatorio
                    </p>
                  </div>
                  <div className="text-center mb-4 mt-8">
                    <SubmitForm value={submitBtnText} enabled={enabledForm} />
                  </div>
                </form>
              </div>
            </div>
          </Col>
          <Col sm={6} className="p-0">
            <div className="ms-lg-5">
              <div className="sub-card-contact bg-light mb-10 customheight preanchor">
                {/* <div className="mb-20 d-flex">
                  <i className="mr-0 mb-0 me-3">
                    <Mail></Mail>
                  </i>
                  <div className="description">info@4doctors.science</div>
                </div>
                <div className="mb-20 mt-4 d-flex">
                  <i className="mr-0 mb-0 me-3">
                    <Location></Location>
                  </i>
                  <div className="description">
                    Avenida Costanera ǀ Torre MMG, Piso 11 ǀ Costa del Este ǀ
                    Ciudad de Panamá l Panamá
                  </div>
                </div> */}
                <div className="mb-5">
                  <div className="text-center">
                    <img
                      src={contact}
                      className="img-calendar my-10 img-fluid img-contact"
                      alt="Imagen calendario de eventos"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
