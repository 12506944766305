import { Progress } from "antd";
import React from "react";
import "../stylesheets/progressbar.scss";

function ProgressBar({value=0, totalValue=1}) {
  const percent = Math.floor( (value / totalValue) * 100 )
  return (
    <div style={{ width: 300 }}>
      <Progress percent={percent} size="small" />
    </div>
  );
}

export default ProgressBar;
