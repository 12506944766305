import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../stylesheets/modals.scss"

function AcceptConditionsModal({show, onClose}) {
  return (
    <Modal show={show} className="modal-conditions">
      <Modal.Header>
        <strong>Condiciones de privacidad</strong>
      </Modal.Header>
      <Modal.Body>
        <p style={{textAlign: "center"}}><strong>AVISO DE PRIVACIDAD</strong></p>
        <p>Este aviso de privacidad está dirigido a todos los visitantes del sitio web de Novo Nordisk Panamá S.A. (“Novo”).</p>
        <p>Es importante para nosotros que comprenda qué datos personales podemos recopilar sobre usted cuando interactúa con Novo a través de su página web <a href="https://www.obesidadalacarta.com/">www.obesidadalacarta.com</a> y con qué finalidad los utilizamos. Lo anterior, de conformidad con lo establecido en la legislación aplicable y normas concordantes. </p>
        <p><strong>I. ¿Quién es el responsable del procesamiento de sus datos personales?</strong></p>
        <p>La compañía responsable de procesar sus datos personales es:</p>
        <p>Razón Social: Novo Nordisk Panamá S.A.</p>
        <p>Domicilio: Avenida Costanera, Torre MMG, Piso 11</p>
        <p>Costa del Este, Ciudad de Panamá</p>
        <p>RUC: 155621514-2-2016</p>
        <p>En cualquier momento puede contactar a Novo al correo <a href="mailto:dataclat@novonordisk.com" target="_blank">dataclat@novonordisk.com</a> con preguntas o preocupaciones sobre cómo procesamos sus datos personales.</p>
        <p><strong>II. ¿Cómo recolectamos sus datos personales?</strong></p>
        <p>En el marco de los servicios, nos da usted su consentimiento y permiso exclusivo para recoger y tratar sus datos personales a partir de las siguientes fuentes:</p>
        <p>i. Directamente de usted</p>
        <p>ii. A  partir de su uso y consumo de los servicios y comunicaciones digitales facilitados por Novo, por ejemplo, sitios web y correos electrónicos.</p>
        <p><strong>III. Usos y finalidades de los datos personales</strong></p>
        <p>Los datos personales serán utilizados para uno o varios de los siguientes propósitos, de acuerdo con sus preferencias</p>
        <p>i. Para realizar un intercambio de información científica y educativa</p>
        <p>ii. Para proporcionar información corporativa e información sobre productos de Novo</p>
        <p>iii. Para cumplir con obligaciones de salud y otras obligaciones legales ante las autoridades sanitarias</p>
        <p>iv. Para hacer reportes a las autoridades sanitarias, relativas a calidad y farmacovigilancia</p>
        <p><strong>IV. ¿Cuáles son sus derechos como titular?</strong></p>
        <p>El titular de datos personales puede ejercitar los derechos de acceso, rectificación, cancelación y oposición respecto a sus datos personales. Asimismo, dicho titular puede limitar el uso o divulgación de sus datos personales y revocar el consentimiento que haya otorgado.</p>
        <p><strong>V. Cookies</strong></p>
        <p>La página del sitio web utiliza Cookies, pequeños ficheros de datos que se generan en el ordenador del usuario y que nos permiten obtener la siguiente información:</p>
        <p>i. La fecha y hora de la última vez que el usuario visitó nuestro web.</p>
        <p>ii. El diseño de contenidos que el usuario escogió en su primera visita a nuestro web.</p>
        <p>iii. Elementos de seguridad que intervienen en el control de acceso a las áreas restringidas.</p>
        <p> </p>
        <p>De ninguna manera podemos obtener su dirección de correo electrónico, o información de su disco rígido, ni transmitir un virus a través del uso de Cookies. El mismo es sólo a los fines que en esta normativa de privacidad se detallan.</p>
        <p> </p>
        <p>El usuario puede borrar el almacenamiento de Cookies de su disco duro, y además tiene la opción de impedir la generación de Cookies mediante la selección de la correspondiente opción en su programa navegador, aunque deberá tener en cuenta que algunos sitios de Internet, así como algunos contenidos del presente sitio web pueden no funcionar correctamente.</p>
        <p> </p>
        <p> </p>
        <p><strong>VI. ¿Cuáles son las medidas de seguridad utilizadas para proteger sus datos personales? </strong></p>
        <p><strong> </strong></p>
        <p>Novo ha implementado y mantiene las medidas de seguridad, técnicas, administrativas y físicas, necesarias para proteger sus datos personales y evitar su daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado.</p>
      </Modal.Body>
      <Modal.Footer>
      <Button
            className="btn-cancel"
            variant="secondary"
            onClick={onClose}
          > Cerrar </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AcceptConditionsModal;
