import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { LOGIN_URL, INSCRIPTION_URL } from "../constants/api-urls";
import { jwtDecode } from "jwt-decode";
import SimpleSpinner from "../components/SimpleSpinner";
import bg_login from "../images/bg_login.jpg";
import logo_login from "../images/logo_login.png";
import logo_novo_blue from "../images/logo_novo_blue.png";
import "../stylesheets/singin.scss";
import "../stylesheets/variables.scss";

const loginIncorrect = "Usuario o contraseña incorrectos";

export const SignIn = ({ pageTitle }) => {
  const location = useLocation();
  const redirect_path = location.state?.from ? location.state?.from : "/";

  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordlInput] = useState("");
  const [notice, setNotice] = useState("");
  const [loading, setLoading] = useState(false);

  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  if (token) {
    const decoded_token = jwtDecode(token)
    window.localStorage.setItem("user", JSON.stringify(decoded_token));
    window.location.href = redirect_path;
    window.localStorage.setItem("user_caducity", Date.now());
  }

  const handleEmailInputChange = (event) => {
    setEmailInput(event.target.value);
  };
  const handlePasswordInputChange = (event) => {
    setPasswordlInput(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    window.localStorage.clear();
    const params = {
      email: emailInput,
      password: passwordInput,
    };

    fetch(LOGIN_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (!response.ok) {          
          setNotice(loginIncorrect);
          throw new Error("Network error.");
        }
        return response.json();
      })
      .then((data) => {
        window.localStorage.setItem("user", JSON.stringify(data.user));
        window.location.href = redirect_path;
        window.localStorage.setItem("user_caducity", data.timestamp);
      })
      .catch((err) => {
        console.log("Connection error: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div id="App" className="h-100 bg-login">
        <div>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
        </div>
        <div className="bg_login"></div>
        <div className="form-container">
          <div className="w-lg-500px form-login">            
            <form onSubmit={handleSubmit}>
              <div className="text-center mb-8">
                <a className="mb-0 mb-lg-12">
                  <img src={logo_login} alt="Logo" className="logo-login"></img>
                </a>
                <h1 className="title-login">Iniciar sesión para acceder</h1>
              </div>
              <div className="fv-row mb-6">
                <label className="textfield-login">Correo electrónico</label>
                <input
                  type="email"
                  className="form-control textarea-login bg-transparent"
                  placeholder="Escriba su correo electrónico"
                  onChange={handleEmailInputChange}
                  required
                />
              </div>
              <div className="fv-row mb-3">
                <label className="textfield-login">Contraseña</label>
                <input
                  type="password"
                  className="form-control textarea-login bg-transparent"
                  placeholder="Escriba su contraseña"
                  onChange={handlePasswordInputChange}
                  required
                />
              </div>
              <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                <a href="/password-reset" className="blue-link">
                  ¿Has olvidado tu contraseña?
                </a>
              </div>
              <div className="d-grid mb-10">
                <button type="submit" className="btn-main" disabled={loading}>
                  {loading ? (
                    <SimpleSpinner/>
                  ) : (
                    "Iniciar sesión"
                  )}
                </button>
                <div>
                  <p className="error-notice text-center mt-3">{notice}</p>
                </div>
              </div>
              <div className="text-violet text-center fw-semibold fs-14">
                ¿No estás dado de alta?
                <a href={INSCRIPTION_URL} className="blue-link ml-1">
                  Solicita tu acceso aquí
                </a>
              </div>
            </form>
          </div>
        </div>
        <div className="footer_login">
            <img
              src={logo_novo_blue}
              alt="Logo"
              className="img_novonordisk"
            ></img>
        </div>
      </div>
    </>
  );
};
