import "../stylesheets/home.scss";
import "../stylesheets/footer1.scss";
import Col from "react-bootstrap/Col";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import img_contacto from "../images/home/img-contacto-home.png";
import Events from "../components/Events";

export const Agenda = () => {
  return (
    <Container>
      <Row id="agenda">
        <Col sm={6} className="card-agenda p-0">
          <div className="ms-lg-5">
            <div
              className="sub-card-agenda mb-10 p-10 customheight preanchor"
            >
              <h1 className="anchor mb-5 section-title text-violet">
                ¿Necesitas ayuda?
              </h1>
              <div class="text-center">
                <img
                  src={img_contacto}
                  className="img-contacto"
                  alt="Imagen calendario de eventos"
                />
              </div>
              <div
                className="mb-20 description mt-4 text-violet"
                data-v-9bf084bd=""
              >
                Si en algún momento tienes alguna duda o problema con respecto
                al contenido del curso o al uso de la plataforma, no dudes en
                contactar con nuestro equipo de docentes. Estamos aquí para
                ayudarte y asegurarnos de que obtengas la mejor experiencia de
                aprendizaje posible.
              </div>
              <div className="text-center mb-8">
                <a
                  href="/contact"
                  className="btn-main btn-lg col-8 btn px-6"
                >
                  Hablemos
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={6} className="card-agenda p-0">
          <Events />
        </Col>
      </Row>
    </Container>
  );
};
