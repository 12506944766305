import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { WebsocketController } from './components/WebsocketController';
import * as Sentry from "@sentry/react";

const root = ReactDOM.createRoot(document.getElementById('root'));
const pathName = window.location.pathname.slice(1);
const user = window.localStorage.getItem('user');
const email = user ? JSON.parse(user).email : null;

Sentry.init({
  dsn: 'https://2eb65ab72b66de0ab7f22121b82675ab@o4506558627381248.ingest.us.sentry.io/4507368560852992',
  integrations: [
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
    }),
  ],
  release: process.env.REACT_APP_RELEASE_VERSION,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (email) {
  Sentry.setUser({ email });
}

root.render(
  <>
    <WebsocketController/>
    <React.StrictMode>
      <App pathName={pathName}/>
    </React.StrictMode>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
