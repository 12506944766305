import React, { useEffect, useState } from "react";
import "../stylesheets/profile.scss";
import "../stylesheets/profileedit.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ArrowRight from "@mui/icons-material/ArrowForwardIos";
import logo_moc from "../images/restaurantes/logo_moc_breadcrumb.png";
import { Helmet } from "react-helmet";
import ProfileHeader from '../components/ProfileHeader.jsx';
import { UPDATE_USER_URL, getAvatarUrl } from "../constants/api-urls.js";
import AvatarInput from "../components/AvatarInput.jsx";

export const ProfileEdit = ({ pageTitle }) => {
  const user = JSON.parse(window.localStorage.getItem('user'));

  const [getAvatar, setAvatar] = useState();
  const [getProfession, setProfession] = useState();
  const [getCountry, setCountry] = useState();
  const [getCity, setCity] = useState();
  const [getBiography, setBiography] = useState();

  function submit() {
    const formData = new FormData();

    if (!!getAvatar) formData.append("avatar", getAvatar);
    if (!!getProfession) formData.append("profession", getProfession);
    if (!!getCountry) formData.append("country", getCountry);
    if (!!getCity) formData.append("city", getCity);
    if (!!getBiography) formData.append("biography", getBiography);

    fetch(UPDATE_USER_URL, {
      method: "PUT",
      credentials: "include",
      body: formData
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network error.");
      }
      return response.json();
    })
    .then((data) => {
      window.localStorage.setItem("user", JSON.stringify(data.user));
      window.location.href = '/perfil';
    })
    .catch((err) => {
      console.log("Connection error: ", err);
    });
  }

  return (
    <>
      <div>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      </div>
      <div className="toolbar-contact">
        <div className="background_toolbar"></div>
      </div>
      <Container id="contact_container">
        <Row className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-6">
          <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-6 my-0 pt-1">
            <li className="breadcrumb-item text-white opacity-85">
              <a className="">
                <img
                  src={logo_moc}
                  className="img-moc-breadcrumb"
                  alt="Imagen calendario de eventos"
                />
              </a>
            </li>
            <li className="text-white opacity-85 mt-6 mx-3">
              <i className="arrow-breadcrumb">
                <ArrowRight></ArrowRight>
              </i>
            </li>
            <li className="breadcrumb-item breadcrumb-title text-white opacity-85 mt-6">
              Perfil
            </li>
          </ul>
        </Row>
        <Row id="profile" className="row">
          <ProfileHeader user={user} showActions={false} />
          <div className="card mb-6">
            <div className="card-header">
              <div className="bio-curriculum">
                <div className="card-title fw-bold titulo-biografia">
                  Editar perfil
                </div>
              </div>
            </div>
            <div className="card-body-edit">
              <Row className="mb-8">
                <Col sm={3}>
                  <label className="fs-6 fw-semibold mt-2 mb-3">Avatar</label>
                </Col>
                <Col sm={9}>
                  <AvatarInput user_avatar={getAvatarUrl(user)} setAvatar={setAvatar} />
                </Col>
              </Row>
              <Row className="mb-8">
                <Col sm={3}>
                  <label className="fs-6 fw-semibold mt-2 mb-3">Nombre</label>
                </Col>
                <Col
                  sm={9}
                  className="fs-2 col-lg-4 fv-row d-flex align-items-center"
                >
                  {user.name}
                </Col>
              </Row>
              <Row className="mb-8">
                <Col sm={3}>
                  <label className="fs-6 fw-semibold mt-2 mb-3">
                    Apellidos
                  </label>
                </Col>
                <Col
                  sm={9}
                  className="fs-2 col-lg-4 fv-row fv-plugins-icon-container d-flex align-items-center"
                >
                  {user.surname}
                </Col>
              </Row>
              <Row className="mb-8">
                <Col sm={3}>
                  <label className="fs-6 fw-semibold mt-2 mb-3">
                    Cargo o titulación
                  </label>
                </Col>
                <Col
                  sm={9}
                  className="fs-2 col-lg-4 fv-row fv-plugins-icon-container d-flex align-items-center"
                >
                  <input
                    className="form-control bg-transparent"
                    name="profession"
                    defaultValue={user.profession}
                    onChange={ e => {setProfession(e.target.value)}}
                  ></input>
                </Col>
              </Row>
              <Row className="mb-8">
                <Col sm={3}>
                  <label className="fs-6 fw-semibold mt-2 mb-3">País</label>
                </Col>
                <Col
                  sm={9}
                  className="fs-2 col-lg-4 fv-row fv-plugins-icon-container d-flex align-items-center"
                >
                  <input
                    className="form-control bg-transparent"
                    name="country"
                    defaultValue={user.country}
                    onChange={ e => {setCountry(e.target.value)}}
                  ></input>
                </Col>
              </Row>
              <Row className="mb-8">
                <Col sm={3}>
                  <label className="fs-6 fw-semibold mt-2 mb-3">
                    Estado o provincia
                  </label>
                </Col>
                <Col
                  sm={9}
                  className="fs-2 col-lg-4 fv-row fv-plugins-icon-container d-flex align-items-center"
                >
                  <input
                    className="form-control bg-transparent"
                    name="city"
                    defaultValue={user.city}
                    onChange={ e => {setCity(e.target.value)}}
                  ></input>
                </Col>
              </Row>
              <Row className="mb-8">
                <Col sm={3}>
                  <label className="fs-6 fw-semibold mt-2 mb-3">
                    Biografía o currículum
                  </label>
                </Col>
                <Col
                  sm={9}
                  className="fs-2 col-lg-4 fv-row fv-plugins-icon-container d-flex align-items-center"
                >
                  <textarea
                    className="form-control"
                    name="problem"
                    defaultValue={user.biography}
                    onChange={ e => {setBiography(e.target.value)}}
                  ></textarea>
                </Col>
              </Row>
            </div>
            <div className="card-footer-edit d-flex justify-content-end py-6">
              <a
                className="btn btn-light btn-active-light-info me-2"
                href="/perfil"
              >
                Descartar
              </a>
              <button type="submit" className="btn btn-main" onClick={submit}>
                <span className="indicator-label">Guardar cambios</span>
              </button>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
