import Offcanvas from './Offcanvas';

function HeaderMoc() {
  return (
    <Offcanvas />                
  );
}

export default HeaderMoc;

