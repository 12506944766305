import { useEffect, useState } from "react";
import { WeboscketMessage } from "./WebsocketMessage";


// const websocketURL = 'ws://localhost:3000/cable'; // LOCAL
const websocketURL = 'wss://stg.obesidadalacarta.com/cable'; // PRODUCCIÓN

export function WebsocketController() {
  const [wsMsgList, setWsMsgList] = useState([]);
  const [newAchievement, setNewAchievement] =  useState();
  let user = JSON.parse(localStorage.getItem('user'));

  useEffect(()=>{
    const websocket = new WebSocket(websocketURL);
    const identifier = {channel: "NotificationsChannel"};
    const identifierJSON = JSON.stringify(identifier);

    websocket.onmessage = function(event) {
      const data = JSON.parse(event.data);
      if (data.type == "ping" || data.type == "welcome") return;
      if (identifierJSON == data.identifier && !!data.message) {
        setNewAchievement(data.message);
      }
    };

    websocket.onopen = function() {
      const subscription = {
        command: "subscribe",
        identifier: identifierJSON
      }
      websocket.send(JSON.stringify(subscription))
    };

    websocket.onerror = function(error) {
      console.error('Error en la conexión WebSocket:', error);
    };
  }, []);

  useEffect(()=>{
    if (!newAchievement) return;
    if (newAchievement.type == "achievement") {
      const achievement = { title: newAchievement.message, timestamp: Date.now()}
      const newList = wsMsgList;
      newList.push(achievement);
      setWsMsgList(newList);
      setNewAchievement();
    }
    
    if (newAchievement.type == "update_user") {
      user.level = newAchievement.message.level
      user.experience = newAchievement.message.experience
      user.study_time = newAchievement.message.study_time
      localStorage.setItem('user', JSON.stringify(user));
    }

  }, [newAchievement])

  function desapear(timestamp) {
    setWsMsgList(wsMsgList.filter(msg => {return msg.timestamp != timestamp}));
  }

  return <>
    {wsMsgList.map(msg => {
      return <WeboscketMessage message={msg.title} onClick={()=>desapear(msg.timestamp)} />
    })}
  </>
}
