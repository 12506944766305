import { BACKEND_URL, CONTENT_VIEWED_URL } from "../constants/api-urls";
import "../stylesheets/home.scss";
import "../stylesheets/plato.scss";
import React from "react";
import poster from "../images/poster.jpg"

function PlatoContenidoVideo({url, handleViewed}) {
  return (
    <div className="my-3 px-10">
      <video onPlay={handleViewed} controls="true" poster={poster} src={`${BACKEND_URL}${url}`} className="video-recurso" type="video/mp4"></video>
    </div>
  );
}

export default PlatoContenidoVideo;
