import { Col } from "react-bootstrap";
import MedalImage1 from "../images/medals/medal1.png";
import MedalImage2 from "../images/medals/medal2.png";
import MedalImage3 from "../images/medals/medal3.png";
import MedalImage4 from "../images/medals/medal4.png";
import MedalImage5 from "../images/medals/medal5.png";
import MedalImage6 from "../images/medals/medal6.png";
import MedalImage7 from "../images/medals/medal7.png";
import MedalImage8 from "../images/medals/medal8.png";
import MedalImage9 from "../images/medals/medal9.png";
import MedalImage10 from "../images/medals/medal10.png";
import MedalImage11 from "../images/medals/medal11.png";
import MedalImage12 from "../images/medals/medal12.png";
import MedalImage13 from "../images/medals/medal13.png";
import MedalImage14 from "../images/medals/medal14.png";
import MedalImage15 from "../images/medals/medal15.png";
import MedalImage16 from "../images/medals/medal16.png";
import MedalImage17 from "../images/medals/medal17.png";
import MedalImage18 from "../images/medals/medal18.png";
import MedalImage19 from "../images/medals/medal19.png";
import MedalImage20 from "../images/medals/medal20.png";
import MedalImage21 from "../images/medals/medal21.png";
import MedalImage22 from "../images/medals/medal22.png";
import MedalImage23 from "../images/medals/medal23.png";
import MedalImage24 from "../images/medals/medal24.png";
import MedalImage25 from "../images/medals/medal25.png";
import MedalImage26 from "../images/medals/medal26.png";
import MedalImage27 from "../images/medals/medal27.png";
import MedalImage28 from "../images/medals/medal28.png";
import MedalImage29 from "../images/medals/medal29.png";
import MedalImage30 from "../images/medals/medal30.png";
import MedalImage31 from "../images/medals/medal31.png";
import MedalImage32 from "../images/medals/medal32.png";
import MedalImage33 from "../images/medals/medal33.png";


export function Medal({ title, medal }) {
  /* Function to select the appropriate image based on the medal variable */
  const getMedalImage = (medal) => {
    const medalString = String(medal); 
    switch (medalString) {
      case '1':
        return MedalImage1;
      case '2':
        return MedalImage2;
      case '3':
        return MedalImage3;
      case '4':
        return MedalImage4;
      case '5':
        return MedalImage5;
      case '6':
        return MedalImage6;
      case '7':
        return MedalImage7;
      case '8':
        return MedalImage8;
      case '9':
        return MedalImage9;
      case '10':
        return MedalImage10;
      case '11':
        return MedalImage11;
      case '12':
        return MedalImage12;
      case '13':
        return MedalImage13;
      case '14':
        return MedalImage14;
      case '15':
        return MedalImage15;
      case '16':
        return MedalImage16;
      case '17':
        return MedalImage17;
      case '18':
        return MedalImage18;
      case '19':
        return MedalImage19;
      case '20':
        return MedalImage20;
      case '21':
        return MedalImage21;
      case '22':
        return MedalImage22;
      case '23':
        return MedalImage23;
      case '24':
        return MedalImage24;
      case '25':
        return MedalImage25;
      case '26':
        return MedalImage26;
      case '27':
        return MedalImage27;
      case '28':
        return MedalImage28;
      case '29':
        return MedalImage29;
      case '30':
        return MedalImage30;
      case '31':
        return MedalImage31;
      case '32':
        return MedalImage32;
      case '33':
        return MedalImage33;
      default:
        return MedalImage1;
    }
  };

  return (
    <Col sm={2}>
      <div className="mb-10 el-tooltip__trigger el-tooltip__trigger">
        <div className="text-center mb-4">
          <img
            src={getMedalImage(medal)}
            className="img-fluid achievement-icon"
            alt="Medal icon"
          />
        </div>
        <p className="text-center h6">{title}</p>
      </div>
    </Col>
  );
}
