import React, { useEffect, useState } from "react";
import "../stylesheets/contact.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CheckboxPrivacidad from "../components/CheckboxPrivacidad";
import infomedico from "../images/contacto/img-infomedico.png";
import { Helmet } from "react-helmet";
import { CONTACT_MEDICAL_URL } from "../constants/api-urls";
import SubmitForm from "../components/SubmitForm";

export const ContactMedic = ({ pageTitle }) => {
  const isLevelEnough =
    JSON.parse(window.localStorage.getItem("user")).level >= 2;

  const input1 = "input-1"; // Nombre y apellidos
  const input2 = "input-2"; // E-mail
  const input3 = "input-3"; // Mensaje
  const input4 = "input-4"; // Profesión
  const input5 = "input-5"; // Especialidad
  const input6 = "input-6"; // País
  const input7 = "input-7"; // Términos y condiciones

  const [enabledForm, setEnabledForm] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [profession, setProfession] = useState();
  const [specialty, setSpecialty] = useState();
  const [country, setCountry] = useState();
  const [accepted, setAccepted] = useState(false);
  const [submitBtnText, setSubmitBtnText] = useState("Enviar");

  useEffect(() => {
    const isEnabled = isLevelEnough && !!name && !!email && !!message && !!profession && !!specialty && !!country && accepted;
    setEnabledForm(isEnabled);
    setSubmitBtnText("Enviar");
  }, [name, email, message, profession, specialty, country, accepted]);

  function changeName(event) {
    setName(event.target.value);
  }

  function changeEmail(event) {
    setEmail(event.target.value);
  }

  function changeMessage(event) {
    setMessage(event.target.value);
  }

  function changeProfession(event) {
    setProfession(event.target.value);
  }

  function changeSpecialty(event) {
    setSpecialty(event.target.value);
  }

  function changeCountry(event) {
    setCountry(event.target.value);
  }

  function isAccepted(event) {
    setAccepted(event.target.checked);
    if (event.target.checked) {
      document.getElementById(input7).setAttribute("hidden", "");
    } else {
      document.getElementById(input7).removeAttribute("hidden");
    }
  }

  function submit(event) {
    event.preventDefault();
    setSubmitBtnText("Enviando mensaje...");
    setEnabledForm(false);

    const params = {
      name: name,
      email: email,
      message: message,
      profession: profession,
      specialty: specialty,
      country: country,
      accept_conditions: accepted,
    };

    fetch(CONTACT_MEDICAL_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (!response.ok) {
          setSubmitBtnText("Ha sucedido un error");
          throw new Error("Network error.");
        }
        setTimeout(() => {
          setSubmitBtnText("Mensaje enviado");
        }, 500);
        return response.json();
      })
      .catch((err) => {
        console.log("Connection error: ", err);
      });
  }

  function adviceIfEmpty(inputName, event) {
    if (!event.target.value) {
      document.getElementById(inputName).removeAttribute("hidden");
    } else {
      document.getElementById(inputName).setAttribute("hidden", "");
    }
  }

  return (
    <>
      <div>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      </div>
      <div className="toolbar-contact">
        <div className="background_toolbar"></div>
      </div>
      <Container id="contact_container">
        <Row className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-6">
          <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-6">
            <li className="breadcrumb-item breadcrumb-title text-white">
              Contacta con el área médica
            </li>
          </ul>
        </Row>
        <Row id="contact" className="row">
          <Col sm={6} className="p-0">
            <div className="me-lg-5">
              <div className="sub-card-contact bg-light mb-16 ustomheight-medical-contact preanchor">
                <h1 className="anchor mb-5 section-title text-violet">
                  Contacta con el área médica
                </h1>
                <div className="mb-22 description text-violet">
                  <b>Si en algún momento tienes alguna duda o problema</b> con respecto
                  al contenido de la plataforma, no dudes en contactar con nuestro equipo
                  médico. Estamos aquí para ayudarte y asegurarnos de que obtengas la
                  mejor experiencia de aprendizaje posible.
                </div>
                {isLevelEnough && (
                  <form onSubmit={submit}>
                    <div className="mb-8">
                      <label className="textfield-contact text-violet">Nombre y apellidos*</label>
                      <input
                        className="form-control"
                        placeholder="Nombre y apellidos"
                        name="NombreApellidos"
                        onChange={(e) => {
                          changeName(e);
                          adviceIfEmpty(input1, e);
                        }}
                        onBlur={(e) => adviceIfEmpty(input1, e)}
                        required
                      />
                      <p hidden className="required-advice" id={input1}>
                        Nombre y apellidos es un campo obligatorio
                      </p>
                    </div>
                    <div className="mb-8">
                      <label className="textfield-contact text-violet">E-mail*</label>
                      <input
                        className="form-control"
                        type="email"
                        placeholder="E-mail"
                        name="Email"
                        onChange={(e) => {
                          changeEmail(e);
                          adviceIfEmpty(input2, e);
                        }}
                        onBlur={(e) => adviceIfEmpty(input2, e)}
                        required
                      />
                      <p hidden className="required-advice" id={input2}>
                        E-mail es un campo obligatorio
                      </p>
                    </div>
                    <div className="mb-8">
                      <label className="textfield-contact text-violet">Mensaje*</label>
                      <textarea
                        className="form-control"
                        placeholder="Mensaje"
                        name="Mensaje"
                        onChange={(e) => {
                          changeMessage(e);
                          adviceIfEmpty(input3, e);
                        }}
                        onBlur={(e) => adviceIfEmpty(input3, e)}
                        required
                      />
                      <p hidden className="required-advice" id={input3}>
                        Mensaje es un campo obligatorio
                      </p>
                    </div>
                    <div className="mb-8">
                      <label className="textfield-contact text-violet">Profesión*</label>
                      <input
                        className="form-control"
                        placeholder="Profesión"
                        name="Profesion"
                        onChange={(e) => {
                          changeProfession(e);
                          adviceIfEmpty(input4, e);
                        }}
                        onBlur={(e) => adviceIfEmpty(input4, e)}
                        required
                      />
                      <p hidden className="required-advice" id={input4}>
                        Profesión es un campo obligatorio
                      </p>
                    </div>
                    <div className="mb-8">
                      <label className="textfield-contact text-violet">Especialidad*</label>
                      <input
                        className="form-control"
                        placeholder="Especialidad"
                        name="Especialidad"
                        onChange={(e) => {
                          changeSpecialty(e);
                          adviceIfEmpty(input5, e);
                        }}
                        onBlur={(e) => adviceIfEmpty(input5, e)}
                        required
                      />
                      <p hidden className="required-advice" id={input5}>
                        Especialidad es un campo obligatorio
                      </p>
                    </div>
                    <div className="mb-8">
                      <label className="textfield-contact text-violet">País*</label>
                      <select
                        className="form-control"
                        placeholder="País"
                        onChange={(e) => {
                          changeCountry(e);
                          adviceIfEmpty(input6, e);
                        }}
                        onBlur={(e) => adviceIfEmpty(input6, e)}
                        required
                      >
                        <option label="País" disabled selected></option>
                        <option label="Argentina" value="Argentina"></option>
                        <option label="Bolivia" value="Bolivia"></option>
                        <option label="Brasil" value="Brasil"></option>
                        <option label="Chile" value="Chile"></option>
                        <option label="Colombia" value="Colombia"></option>
                        <option label="Costa Rica" value="Costa Rica"></option>
                        <option label="Cuba" value="Cuba"></option>
                        <option label="Ecuador" value="Ecuador"></option>
                        <option label="El Salvador" value="El Salvador"></option>
                        <option label="España" value="España"></option>
                        <option label="Guatemala" value="Guatemala"></option>
                        <option label="Honduras" value="Honduras"></option>
                        <option label="México" value="México"></option>
                        <option label="Nicaragua" value="Nicaragua"></option>
                        <option label="Panamá" value="Panamá"></option>
                        <option label="Paraguay" value="Paraguay"></option>
                        <option label="Perú" value="Perú"></option>
                        <option label="Puerto Rico" value="Puerto Rico"></option>
                        <option label="República Dominicana" value="República Dominicana"></option>
                        <option label="Uruguay" value="Uruguay"></option>
                        <option label="Venezuela" value="Venezuela"></option>
                        <option label="Estados Unidos" value="Estados Unidos"></option>
                      </select>
                      <p hidden className="required-advice" id={input6}>
                        País es un campo obligatorio
                      </p>
                    </div>
                    <div className="text-center">
                      <CheckboxPrivacidad
                        getCheck={(event) => {
                          isAccepted(event);
                        }}
                      />
                      <p hidden className="required-advice" id={input7}>
                        Términos y condiciones es un campo obligatorio
                      </p>
                    </div>
                    <div className="text-center mb-4 mt-8">
                      <SubmitForm value={submitBtnText} enabled={enabledForm} />
                    </div>
                  </form>
                )}
                {!isLevelEnough && (
                  <div className="mb-22 description text-violet">
                    <b>Para acceder al formulario, debe alcanzar el nivel 2.</b>
                    <p>Continúe su formación visualizando contenido de la plataforma para ganar experiencia y desbloquear el formulario.</p>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col sm={6} className="p-0">
            <div className="ms-lg-5">
              <div className="sub-card-contact bg-light mb-10 preanchor">
                <div className="mb-5">
                  <div className="text-center">
                    <img
                      src={infomedico}
                      className="img-contactmedic img-fluid"
                      alt="Imagen calendario de eventos"
                    />
                    <button className="btn-main" href="/contact">
                      Contacta con MOC
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
