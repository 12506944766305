import React, { useEffect, useState } from "react";
import "../stylesheets/profile.scss";
import "../stylesheets/profileedit.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ArrowRight from "@mui/icons-material/ArrowForwardIos";
import logo_moc from "../images/restaurantes/logo_moc_breadcrumb.png";
import { Helmet } from "react-helmet";
import LogrosInfo from "../components/LogrosInfo";
import ProfileHeader from "../components/ProfileHeader";
import { GET_ACHIEVEMENTS_URL } from "../constants/api-urls";

export const Logros = ({ pageTitle }) => {
  const user = JSON.parse(window.localStorage.getItem('user'));
  const [usersAchievements, setUsersAchievements] = useState([]);
  const [restAchievements, setRestAchievements] = useState([]);

  useEffect(()=>{
    fetch(GET_ACHIEVEMENTS_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network error.");
      }
      return response.json();
    })
    .then((data) => {
      const dataWithTime = data.filter( achievement => {return !!achievement.time});
      const dataWithoutTime = data.filter( achievement => {return !achievement.time});
      setUsersAchievements(dataWithTime);
      setRestAchievements(dataWithoutTime);
    })
    .catch((err) => {
      console.log("Connection error: ", err);
    });
  }, []);

  return (
    <>
      <div>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      </div>
      <div className="toolbar-contact">
        <div className="background_toolbar"></div>
      </div>
      <Container id="contact_container">
        <Row className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-6">
          <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-6 my-0 pt-1">
            <li className="breadcrumb-item text-white opacity-85">
              <a className="">
                <img
                  src={logo_moc}
                  className="img-moc-breadcrumb"
                  alt="Imagen calendario de eventos"
                />
              </a>
            </li>
            <li className="text-white opacity-85 mt-6 mx-3">
              <i className="arrow-breadcrumb">
                <ArrowRight></ArrowRight>
              </i>
            </li>
            <li className="breadcrumb-item breadcrumb-title text-white opacity-85 mt-6">
              Medallas y logros
            </li>
          </ul>
        </Row>
        <Row id="profile" className="row">
          <ProfileHeader user={user} />
          <div className="card mb-6">
            <div className="card-header">
              <div className="bio-curriculum">
                <div className="card-title fw-bold titulo-biografia">
                  Logros conseguidos
                </div>
              </div>
            </div>
            <div className="card-body texto-biografia mb-5 row py-4 d-none d-sm-flex">
              {usersAchievements.map(achievement => {
                return <LogrosInfo
                  title={achievement.title}
                  description={achievement.description}
                  time={achievement.time}
                  id={achievement.id}
                  key={achievement.id}
                  />
              })}
            </div>
          </div>
          <div className="card mb-6">
            <div className="card-header">
              <div className="bio-curriculum">
                <div className="card-title fw-bold titulo-biografia">
                  Logros pendientes
                </div>
              </div>
            </div>
            <div className="card-body texto-biografia mb-5 row py-4 d-none d-sm-flex img-gray">
              {restAchievements.map(achievement => {
                return <LogrosInfo
                  title={achievement.title}
                  description={achievement.description}
                  id={achievement.id}
                  key={achievement.id}
                  />
              })}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
