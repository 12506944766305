import React from "react";

const SubmitForm = ({ enabled, value }) => {
  return (
    <button type="submit" className="btn-main" disabled={!enabled}>
      {" "}
      {value || "Enviar"}{" "}
    </button>
  );
};

export default SubmitForm;
