import * as React from "react";
import Paginator from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import "../stylesheets/pagination.scss";

export default function Pagination({currentPage, totalPages, onPageChange}) {

  const handlePageChange = (event, page) => {
    onPageChange(page);
  };

  return (
    <Stack spacing={2}>
      <Paginator 
        count={totalPages} 
        page={currentPage}
        onChange={handlePageChange}
        showFirstButton 
        showLastButton 
      />
    </Stack>
  );
}
