import { useEffect, useState } from "react";
import "../stylesheets/websocket_message.scss";
import HEstudio from "../images/profile/hestudio.png";

export function WeboscketMessage({ message, onClick }) {
  return (
    <div className="websocket-message" onClick={onClick}>
      <img
        src={HEstudio}
        className="img-fluid achievement-icon"
        alt="Imagen"
      />
      <div className="message-content">
        <p>Nuevo logro desbloqueado</p>
        <p>{message}</p>
      </div>
    </div>
  );
}
