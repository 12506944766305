import React, { useEffect, useState } from "react";
import "../stylesheets/contact.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import calendar from "../images/events/calendar-home.png";
import { Helmet } from "react-helmet";
import { CONTACT_US_URL } from "../constants/api-urls";
import Events from "../components/Events";

export const AgendaEventos = ({ pageTitle }) => {
  const input1 = "input-1";
  const input2 = "input-2";
  const input3 = "input-3";
  const input4 = "input-4";

  const [enabledForm, setEnabledForm] = useState(false);
  const [typeConsulting, setTypeConsulting] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const [accepted, setAccepted] = useState(false);
  const [submitBtnText, setSubmitBtnText] = useState("Enviar");

  useEffect(() => {
    const isEnabled = !!typeConsulting && !!subject && !!message && accepted;
    setEnabledForm(isEnabled);
    setSubmitBtnText("Enviar");
  }, [typeConsulting, subject, message, accepted]);

  function changeTypeConsulting(event) {
    setTypeConsulting(event.target.value);
  }

  function changeSubject(event) {
    setSubject(event.target.value);
  }

  function changeMessage(event) {
    setMessage(event.target.value);
  }

  function isAccepted(event) {
    setAccepted(event.target.checked);
    if (event.target.checked) {
      document.getElementById(input4).setAttribute("hidden", "");
    } else {
      document.getElementById(input4).removeAttribute("hidden");
    }
  }

  function submit(event) {
    event.preventDefault();
    setSubmitBtnText("Enviando mensaje...");
    setEnabledForm(false);

    const params = {
      type_message: typeConsulting,
      subject: subject,
      message: message,
      accept_conditions: accepted,
    };

    fetch(CONTACT_US_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (!response.ok) {
          setSubmitBtnText("Ha sucedido un error");
          throw new Error("Network error.");
        }
        setTimeout(() => {
          setSubmitBtnText("Mensaje enviado");
        }, 500);
        return response.json();
      })
      .catch((err) => {
        console.log("Connection error: ", err);
      });
  }

  function adviceIfEmpty(inputName, event) {
    if (!event.target.value) {
      document.getElementById(inputName).removeAttribute("hidden");
    } else {
      document.getElementById(inputName).setAttribute("hidden", "");
    }
  }

  return (
    <>
      <div>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      </div>
      <div className="toolbar-contact">
        <div className="background_toolbar"></div>
      </div>
      <Container id="agenda_container">
        <Row className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-6">
            <div className="breadcrumb-item breadcrumb-title text-white">
              Agenda de eventos
            </div>
        </Row>
        <Row id="contact" className="row">
          <Col sm={6} className="p-0">
            <div className="me-lg-5">
              <div className="sub-card-contact bg-light mb-10 preanchor">
                <Events />
              </div>
            </div>
          </Col>
          <Col sm={6} className="p-0">
            <div className="ms-lg-5">
              <div className="sub-card-contact bg-light mb-10 preanchor">
                <div className="mb-5">
                  <div className="text-center">
                    <img
                      src={calendar}
                      className="img-calendar my-10 img-fluid img-contact"
                      alt="Imagen calendario de eventos"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};