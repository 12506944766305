import React, { useState } from "react";
import { Layout, Flex } from "antd";
import "bootstrap/dist/css/bootstrap.css";
import "./stylesheets/App.scss";
import "./stylesheets/index.scss";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import FooterMoc from "./components/Footer";
import HeaderMoc from "./components/Header";
import { AppRouter } from "./routes";

const { Header, Footer, Content } = Layout;
const user = window.localStorage.getItem('user');

function App({ pathName }) {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  return (
    <div className={`App ${pathName}`}>
      <Flex>
        <Layout>
          {user ? <Header className="bg-white">
            <HeaderMoc />
          </Header> : null}
          <Layout className="bg-white">
            <Content>
            <AppRouter setCurrentPath={setCurrentPath} />
            </Content>
          </Layout>
          {user ? <Footer className="bg-secundary">
            <FooterMoc currentPath={currentPath} />
          </Footer> : null}          
        </Layout>
      </Flex>
    </div>
  );
}

export default App;
