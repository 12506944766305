import "../stylesheets/home.scss";
import React, { useEffect, useState } from "react";
import HomeCarousel from "../components/HomeCarousel";
import { Agenda } from "../components/Agenda";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BACKEND_URL, CONTENT_VIEWED_URL, GET_CATEGORIES_URL } from "../constants/api-urls";
import img_bienvenida from "../images/home/img-bienvenida2.png";

export const Home = ({ pageTitle }) => {
  const [categories, setCategories] = useState([]);
  const handleClickButtonAchievements = () => {
    window.location.href = '/logros';
  };
  const getLogoUrl = (id) => {
    try {
      return require(`../images/home/restaurante/logo/img-restaurante-logo-${id}.png`);
    } catch (error) {
      console.error("Error loading image:", error);
      return ''; 
    }
  };
  const getImgCardUrl = (id) => {
    try {
      return require(`../images/home/restaurante/card/img-restaurant-card-${id}.png`);
    } catch (error) {
      console.error("Error loading image:", error);
      return ''; 
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(GET_CATEGORIES_URL, {
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  function handleViewed(id){
    fetch(CONTENT_VIEWED_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({digital_entity_id: id}),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network error.");
      }
    })
    .catch((err) => {
      console.log("Connection error: ", err);
    });
  }
  return (
    <>
      <div>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      </div>
      <div className="toolbar-home bg-lightlavander">
        <div className="background_toolbar"></div>
      </div>
      <Container id="home_container">
        <Row className="card mb-10 carousel">
          <div id="carousel_home">
            <HomeCarousel />
          </div>
        </Row>
        <Row className="card mb-10 briefing">
          <div className="briefing-container bg-lightlavander">
            <div className="row align-items-center desktop">
              <Col sm={7} className="col-sm-12 col-md-7 bienvenida-seccion">
                <h1 className="anchor briefing-title text-violet">
                  Bienvenidos a Obesidad a la Carta
                </h1>
                <div className="briefing-text text-violet">
                  <p>
                    <b>Obesidad a la Carta</b> ha sido creado específicamente para profesionales de la salud interesados en ampliar sus conocimientos y habilidades en el abordaje de esta problemática cada vez más prevalente. Aquí encontrarás una amplia oferta de formación, diseñada para profundizar en los aspectos más relevantes de la evaluación, diagnóstico, tratamiento y seguimiento del sobrepeso y la obesidad en pacientes.
                  </p>
                  <p>
                    Además, nuestro equipo de expertos en medicina, nutrición y psicología te ofrecerá herramientas y estrategias prácticas basadas en la evidencia científica más reciente, para que puedas brindar a tus pacientes una atención de calidad, integral y personalizada.
                  </p>
                </div>
              </Col>
              <Col sm={5} className="anchor col-sm-12 col-md-5">
                <img
                  src={img_bienvenida}
                  className="img-bienvenida img-fluid"
                  alt="Imagen bienvenida doctora"
                />
              </Col>
            </div>
          </div>
        </Row>
        <Row className="categories">
          <h1 className="anchor mb-8 section-title text-violet">
            Nuestros contenidos
          </h1>
          {categories.map((category) => (
            <div
              className={`card bg-light mb-10 p-0 restaurant-${category.id}`}
            >
              <a href={`/restaurante/${category.id}`}>
                <div className="card-body-category">
                  <div className="d-flex row">
                    <Col sm={3} className="card-restaurant-left">
                      <img
                        src={getImgCardUrl(category.id)}
                        className="logo-category-card"
                        width="120px;"
                        alt={`Imagen card ${category.title}`}
                      />
                    </Col>
                    <Col sm={9} className="col-lg-9 card-restaurant-right">
                      <div className="logo-title-card-restaurant">
                        <img
                          src={getLogoUrl(category.id)}
                          width="120px;"
                          alt={`Logo ${category.title}`}
                        />
                        <div className="title-category-card text-violet">
                          {category.title}
                        </div>                        
                      </div>

                      <div className="text-category-card text-violet">
                        {category.description}
                      </div>
                      <span class="btn-seecontent">Ir al restaurante</span>
                    </Col>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </Row>
        <Row className="card-sistemalogros">
          <div className="sistemalogros-container bg-lightlavander">
            <div className="briefing-text text-violet">
              <h1 className="title-sistemalogros">Nuestro sistema de logros</h1>
              <p className="description-sistemalogros">
                El sistema de logros en <b>Obesidad a la Carta</b> está diseñado para incentivar y reconocer el compromiso de los profesionales de la salud con su desarrollo continuo y la mejora de la calidad asistencial. A través de la participación activa en los restaurantes, los usuarios pueden obtener distintivos y certificaciones que reflejan su dedicación y competencia en diversas áreas de especialización.
              </p>
              <button class="btn-lg btn-logroshome text-violet" onClick={handleClickButtonAchievements}>Ver todos los logros</button>
            </div>
          </div>
        </Row>
        <Agenda />
      </Container>
    </>
  );
};
