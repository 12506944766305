import { Avatar } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import { useState } from 'react';

function AvatarInput({user_avatar, setAvatar}) {
  const [previewAvatar, setPreviewAvatar] = useState(user_avatar);

  function isImage(file) {
    const extension = file.split('.').pop();
    return ['jpg', 'jpeg', 'png'].includes(extension.toLowerCase());
  }

  const handleAvatar = event => {
    const input =  event.target;

    if (!isImage(input.value)){
      input.value = '';
      setPreviewAvatar(user_avatar);
      return;
    }

    const file = input.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setPreviewAvatar(reader.result);
    }

    if (file) {
      reader.readAsDataURL(file);
      setAvatar(file)
    }
  }

  return <>
    <div className="symbol">
      <div className="position-btn-edit">
        <label className="btn-edit-avatar">
          <EditIcon/>
          <input
            hidden
            type="file"
            accept="image/png, image/jpg, image/jpeg"
            name="avatar"
            onChange={handleAvatar}
          />
        </label>
      </div>
      <Avatar
        src={previewAvatar}
        alt="Avatar de usuario"
        sx={{width: 160, height: 160}}
      />
    </div>
    <div className="form-text mt-4">
      Tipos de archivo permitidos: png, jpg, y jpeg.
    </div>
  </>
}

export default AvatarInput;