import { GET_TOTAL_COUNT_URL, getAvatarUrl } from "../constants/api-urls.js";
import VerifiedIcon from "@mui/icons-material/Verified";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import MailIcon from "@mui/icons-material/Mail";
import HelpIcon from "@mui/icons-material/Help";
import DropdownActions from "../components/DropdownActions";
import ProgressBar from "../components/ProgressBar";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Avatar } from '@mui/material';

function ProfileHeader({user, showActions=true}) {
  const contentViewed = Math.floor(user.experience / 10);
  const [totalContents, setTotalContents] = useState(1000);

  useEffect(()=>{
    const value = window.localStorage.getItem('totalContents');
    if (!!value) {
      setTotalContents(value);
    } else {
      fetch(GET_TOTAL_COUNT_URL, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network error.");
        }
        return response.json();
      })
      .then((data) => {
        setTotalContents(data)
      })
      .catch((err) => {
        console.log("Connection error: ", err);
      });
    }
  },[]);

  return (
    <div className="card mb-6 p-0">
      <div className="card-body-inf-profile">
        <Col sm={2} className="card-profile me-7 mb-4">
          <div>
            <Avatar
              src={getAvatarUrl(user)}
              alt="Avatar de usuario"
              sx={{width: 160, height: 160}}
            />
          </div>
        </Col>
        <Col sm={10} className="me-7 mb-4">
          <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
            <div className="d-flex flex-column flex-grow-1 pe-8">
              <div className="d-flex align-items-center mb-2">
                <div className="text-gray-900 fs-2 fw-bold me-1 text-silver">
                  {user.name} {user.surname}
                </div>
                <i className="verified-icon">
                  <VerifiedIcon/>
                </i>
              </div>
              <div className="d-flex flex-wrap mb-4 pe-2">
                <div className="d-flex align-items-center text-gray-400 me-5 mb-2">
                  <i className="icon-profile">
                    <AccountCircleIcon/>
                  </i>
                  <div>{user.profession || '-'}</div>
                  <i className="icon-profile">
                    <FmdGoodIcon/>
                  </i>
                  <div>{user.city || '-'}, {user.country || '-'}</div>
                  <i className="icon-profile">
                    <MailIcon/>
                  </i>
                  <div>{user.email}</div>
                </div>
              </div>
            </div>
            {showActions && <div className="d-flex text-lg-end w-200px w-sm-300px flex-column mt-3">
              <DropdownActions/>
            </div>}
          </div>
          <div className="d-flex flex-wrap flex-stack">
            <div className="d-flex flex-column flex-grow-1 pe-8">
              <div className="d-flex flex-wrap">
                <div className="counter-profile me-6">
                  <div className="fs-2 fw-bold counted">{user.study_time}</div>
                  <div className="ffw-semibold fs-6 text-gray-400">
                    Minutos estudio
                  </div>
                </div>
                <div className="counter-profile">
                  <div className="fs-2 fw-bold counted">{contentViewed}</div>
                  <div className="ffw-semibold fs-6 text-gray-400">
                    Contenidos accedidos
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center w-300 flex-column mt-3">
              <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                <div className="d-flex">
                  <p className="fw-semibold fs-6 text-gray-400 me-3">
                    Nivel de usuario:
                  </p>
                  <p className="fw-bold fs-6">{user.level}</p>
                </div>
                <p className="fw-bold fs-6">{user.experience}</p>
              </div>
              <div className="progress-bar">
                <ProgressBar value={contentViewed} totalValue={totalContents} />
              </div>
              <div className="d-flex w-100 justify-content-end">
                <i className="ms-2 me-2" title="El nivel de usuario indica la cantidad de recursos formativos que has visto en MOC. Cuantos más recursos veas, más alto será tu nivel de usuario.">
                  <div className="svg-help">
                    <HelpIcon/>
                  </div>
                </i>
                <p className="text-gray-500">¿Qué es esto?</p>
              </div>
            </div>
          </div>
        </Col>
      </div>
    </div>
  )
}

export default ProfileHeader;
