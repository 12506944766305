import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import AcceptConditionsModal from "./AcceptConditionsModal";

const CheckboxPrivacidad = ({getCheck, disabled=false}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Checkbox className="text-gray-700" onChange={getCheck} disabled={disabled}>
        Acepto las
      </Checkbox>
      <a className="botonPrivacidad" onClick={()=>setShowModal(true)}>condiciones legales y de privacidad</a>
      <AcceptConditionsModal show={showModal} onClose={()=>setShowModal(false)} />
    </>
  );
}

export default CheckboxPrivacidad;
