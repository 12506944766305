import React, { useEffect, useState } from "react";
import "../stylesheets/restaurant.scss";
import "../stylesheets/plato.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Like from "@mui/icons-material/Favorite";
import ArrowRight from "@mui/icons-material/ArrowForwardIos";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import infografia from "../images/sugerencias/infografia.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { BASE_URL, BACKEND_URL, GET_DIGITAL_ENTITIES_URL } from "../constants/api-urls";
import PlatoContenido from "../components/PlatoContenido";
import principal from "../images/restaurantes/img-principal2.png";
import SimpleSpinner from "../components/SimpleSpinner";

export const Plato = () => {
  const { category_id, id } = useParams();
  const [category, setCategory] = useState();
  const [plato, setPlato] = useState();
  const [recommendations, setRecommendations] = useState([]);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getLogoUrl = (id) => {
    try {
      return require(`../images/home/restaurante/logo/img-restaurante-logo-${id}.png`);
    } catch (error) {
      console.error("Error loading image:", error);
      return ''; 
    }
  };

  const getImgCardUrl = (id) => {
    try {
      return require(`../images/home/restaurante/card/img-restaurant-card-${id}.png`);
    } catch (error) {
      console.error("Error loading image:", error);
      return ''; 
    }
  };

  useEffect(() => {
    getCategry(`${GET_DIGITAL_ENTITIES_URL}?ids=${category_id}`);
    getPlato(`${GET_DIGITAL_ENTITIES_URL}?ids=${id}`);
    const user = JSON.parse(window.localStorage.getItem("user"));
    getBookmark(`${BASE_URL}bookmarks/getUserBookmarks?user_id=${user.id}`);
  }, []);

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}digital_entities/recomendations`,
          {
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const recommendations = await response.json();
        setRecommendations(recommendations);
      } catch (error) {
        console.error("Error fetching recommendations:", error);
      }
    };

    fetchRecommendations();
  }, []);

  function getCategry(url) {
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network error.");
        }
        return response.json();
      })
      .then((data) => {
        setCategory(data[0]);
      })
      .catch((err) => {
        console.log("Connection error: ", err);
      });
  }

  function getPlato(url) {
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network error.");
        }
        return response.json();
      })
      .then((data) => {
        setPlato(data[0]);
      })
      .catch((err) => {
        console.log("Connection error: ", err);
      });
  }

  function getBookmark(url) {
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network error.");
        }
        return response.json();
      })
      .then((data) => {
        setIsBookmarked(data.some(item => item.digital_entity_id == id));
      })
      .catch((err) => {
        console.log("Connection error: ", err);
      });
 
  }
  
  const handleCreateBookmark = async () => {
    setIsLoading(true);
    const current_user_id = JSON.parse(localStorage.getItem("user")).id;
    const url = isBookmarked
      ? `${BASE_URL}bookmarks/removeUserBookmark?user_id=${current_user_id}&resource_id=${id}`
      : `${BASE_URL}bookmarks/addUserBookmark?user_id=${current_user_id}&resource_id=${id}`;
    const method = isBookmarked ? "DELETE" : "POST";

    try {
      const response = await fetch(url, {
        method: method,
        credentials: "include",
      });

      if (response.ok) {
        setIsBookmarked(!isBookmarked);
      } else {
        console.error("Post failed");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function getTypeCSS(type) {
    return {
      Entrante: "entrante",
      "Plato principal": "principal",
      Postre: "postre",
    }[type];
  }

   // Función para obtener la clase del badge en el carousel
   const getBadgeClass = (subtype) => {
    switch (subtype) {
      case 'Infografía':
        return 'bg-badge-entrante text-violet';
      case 'MasterClass':
        return 'bg-badge-principal text-lavander';
      case 'Recomendación de experto':
        return 'bg-badge-entrante text-violet';
      case 'Caso Clínico Interactivo':
        return 'bg-badge-postre text-platpostre';
      case 'Píldoras informativas':
        return 'bg-badge-entrante text-violet';
      case 'Casos clínicos':
        return 'bg-badge-postre text-platpostre';
      case 'Curso':
          return 'bg-badge-principal text-lavander';
      default:
        return 'bg-transparent';
    }
  };

  return (
    <>
      <div>
        <Helmet>
          <title>{category && category.title}</title>
        </Helmet>
      </div>
      <div className={`toolbar-${category_id} toolbar-general`}>
        <div className="background_toolbar"></div>
      </div>
      <Container id="restaurant_container">
        <Row className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-6">
          <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-6 my-0 pt-1">
            <li className="breadcrumb-item text-white opacity-85">
              Restaurante
            </li>
            <li className="text-white opacity-85 mx-3">
              <i className="arrow-breadcrumb">
                <ArrowRight />
              </i>
            </li>
            <li className="breadcrumb-item breadcrumb-title text-white opacity-85">
              {category && category.title}
            </li>
          </ul>
        </Row>
        <Row className="card">
          <div className="position-absolute text-lg-end top-0 end-0">
            <button className="heart-btn liked p-5" onClick={handleCreateBookmark}>
              {isLoading ? (
                <SimpleSpinner />
              ) : (
                <i className="heart heart-icon">
                  <Like color={isBookmarked ? "error" : "inherit"} />
                </i>
              )}
            </button>
          </div>
          <div className="card-presentation">
            <Col
              sm={2}
              className="col-lg-2 d-flex justify-content-around align-items-center"
            >
              <img
                src={getImgCardUrl(category_id)}
                className="img-restaurant img-fluid w-200px flex-end"
                alt={`Logo ${category && category.title}`}
              />
            </Col>
            <Col sm={10} className="col-lg-10 px-10">
              <div className="logo-title-card-restaurant">
                <img src={getLogoUrl(category_id)} width="120px;" alt="Logo manejo clínico de la obesidad"></img>
                <div className="title-tarjeta text-violet mb-2">
                  {category && category.title}
                </div>
              </div>
              <div
                className="category-description text-violet"
                data-v-9bf084bd=""
              >
                {category && category.description}
              </div>
            </Col>
          </div>
          <div className="card-presentation">
            <Col sm={2} className="d-flex">
              <div>
                <img
                  src={principal}
                  className="img-menus"
                  alt="Imagen primer plato"
                />
              </div>
            </Col>
            <Col sm={10}>
              <div className="status-recurso">
                <span
                  className={`text bg-blanco text-${getTypeCSS(
                    plato && plato.type
                  )} ps-0 pe-4 mb-2`}
                >
                  {plato && plato.type}
                </span>
                <span className="bg-recomendacionexperto">
                  {plato && plato.subtype}
                </span>
              </div>
              <div className="mb-2 title-tarjeta text-violet">
                {plato && plato.title}
              </div>
              <div className="description-tarjeta text-violet" if="resource.abstract">
                {plato && plato.abstract}
              </div>
              <div className="disclaimer mb-2">{plato && plato.id === 59 && '"Este material no pertenece a Novo Nordisk, ni es de uso exclusivo."'}</div>
              <span className="badge-success ms-3">{plato && plato.viewed && "✓ Accedido"}</span>
            </Col>
          </div>

          <PlatoContenido
            id={plato && plato.id}
            fileType={plato && plato.fileType}
            documentUrl={plato && plato.document_url}
            infographyUrl={plato && plato.infography_url}
            type={plato && plato.subtype}
          />
        </Row>
        <Row className="card mb-6">
          <div className="restaurant-content">
            <div className="card-title section-title mb-4 text-violet">
              Otros contenidos
            </div>
            <OwlCarousel
              className="owl-theme"
              loop
              margin={5}
              nav
              dots={false}
              responsive={{
                0: { items: 1.5 },
                600: { items: 3 },
                1000: { items: 4.2 },
              }}
            >
              {recommendations.map((item) => (
                <div key={item.id} className="item card-sugerencias">
                  <a href={`/restaurante/${item.parent_id}/plato/${item.id}`}>
                    <div className="p-6">
                      <div className="col">
                        <div className="col d-flex justify-content-around align-items-center mb-3">
                          <img
                            src={`${BACKEND_URL}${item.thumbnail_url}`}
                            className="img-fluid img-sugerencias"
                            alt={item.title}
                          />
                        </div>
                        <a
                          className={`badge bg-transparent ${getBadgeClass(item.subtype)}`}
                        >
                          {item.subtype}
                        </a>
                        <div className="fw-semibold text-tarjeta text-violet truncate-multiline-carousel">
                          {item.title}
                        </div>
                        <div className="btn-seecontent-suggeriment">
                          Ver contenido
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </Row>
      </Container>
    </>
  );
};
