// BACKEND URL
// Development
// export const BACKEND_URL = 'http://localhost:3000';
// Production
export const BACKEND_URL = 'https://stg.obesidadalacarta.com';

// FILES URLS
export function getFileUrl(path) {
  return `${BACKEND_URL}${path}`
}

// API URLS
export const BASE_URL = `${BACKEND_URL}/api/v2/`;

// USER
export const LOGIN_URL = `${BASE_URL}users/login`;
export const LOGOUT_URL = `${BASE_URL}users/logout`;
export const RESET_PASSWORD_URL = `${BASE_URL}users/resetPasswordRequest`;
export const CHANGE_PASSWORD_URL = `${BASE_URL}users/change_password`;
export const UPDATE_USER_URL = `${BASE_URL}users/updateUser`;
export const RESET_ACHIEVEMENTS_URL = `${BASE_URL}users/resetAchievements`;

// DIGITAL ENTITIES
export const GET_CATEGORIES_URL = `${BASE_URL}digital_entities/getCategories`;
export const GET_DIGITAL_ENTITIES_URL = `${BASE_URL}digital_entities/getResources`
export const GET_TOTAL_COUNT_URL = `${BASE_URL}digital_entities/all_count`
export const CONTENT_VIEWED_URL = `${BASE_URL}digital_entities/viewedContent`
export const GET_RECOMENDATIONS_URL = `${BASE_URL}digital_entities/recomendations`
export const GET_DOCUMENT_URL = `${BASE_URL}digital_entities/document`

// INSCRIPTION (darse de alta)
export const INSCRIPTION_URL = `${BACKEND_URL}/inscripcion`;

// SSO to Moodle
export const SSO_URL = `${BASE_URL}sso/accessMoodle`

// CONTACT FORMS
export const CONTACT_US_URL = `${BASE_URL}contacts/to_us`;
export const CONTACT_MEDICAL_URL = `${BASE_URL}contacts/to_medical`;

// AVATAR URL
export function getAvatarUrl(user) {
  return !!user.avatar && !!user.avatar.url ? `${BACKEND_URL}${user.avatar.url}` : null
}

// ACHIVEMENTS URL
export const GET_ACHIEVEMENTS_URL = `${BASE_URL}achievements/get`;

// AGENDA EVENTS SEND 'SAVED' URLS
export const SEND_SAVED_EVENT = `${BASE_URL}events/saved`

// INTERESTS LIST
export const GET_ALL_INTERESTS_URL = `${BASE_URL}interests/get`;
export const GET_USERS_INTERESTS_URL = `${BASE_URL}interests/get_from_user`;
export const DEFINE_INTERESTS_URL = `${BASE_URL}interests/set_to_user`;

// SSO OBTAIN AUTH_TOKEN
export const SSO_COOKIE_URL = `${BASE_URL}users/set_sso_cookie`;