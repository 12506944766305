import logo_novo_blue from "../images/logo_novo_blue.png";
import Col from "react-bootstrap/Col";
import React from "react";
import Container from "react-bootstrap/Container";
import "../stylesheets/footer1.scss";

function FooterMoc({ currentPath }) {

  const renderCodigo = () => {
    // Determinar si la ruta es para un restaurante o contenido
    if (
      currentPath.startsWith("/restaurante") ||
      currentPath.startsWith("/plato")
    ) {
      return <div className="codigo-contenido">CLA24OB00146</div>;
    } else {
      // Para el resto de la plataforma
      return <div className="codigo-plataforma">CLA24OB00091</div>;
    }
  };

  return (
    <div className="align-items-center bg-white">
      <Container className="container-xxl bg-lightlavander">
        <div className="d-flex align-items-center justify-content-center text-center p-0 m-0">
          <div className="row container-xxl">
            <Col sm={6} className="py-10 text-lg-end">
              <a
                className="btn btn-lg col-12 col-sm-10 col-md-12 col-lg-10 btn-secondary btn-footer me-lg-5 fs-3 w-100"
                href="/contact"
              >
                Contacta con MOC
              </a>
            </Col>
            <Col sm={6} className="py-10 text-lg-start">
              <a
                className="btn btn-lg col-12 col-sm-10 col-md-12 col-lg-10 btn-secondary btn-footer ms-lg-5 fs-3 w-100"
                href="/area-medica"
              >
                Contacta con el área médica
              </a>
            </Col>
          </div>
        </div>
      </Container>
      <Container className="container">
        <div className="row mt-5">
          <Col sm={10} className="text-bluenovo my-4 ms-10">
            Este programa es desarrollado por Novo Nordisk. Para uso exclusivo
            del profesional de la salud y en Guatemala para uso exclusivo del
            profesional médico. Novo Nordisk® es una marca registrada propiedad
            de Novo Nordisk A/S. Material revisado y aprobado por la Dirección
            de Asuntos Médicos y Regulatorios de Novo Nordisk. Saxenda®
            propiedad de Novo Nordisk A/S.
            <p className="pt-2">{renderCodigo()}</p>
          </Col>
          <Col sm={2} className="text-lg-end">
            <img
              src={logo_novo_blue}
              className="img-novo"
              width="111px"
              alt="Logo"
            />
          </Col>
        </div>
      </Container>
    </div>
  );
}

export default FooterMoc;
