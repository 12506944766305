import { DNA } from 'react-loader-spinner';
export default function Spinner() {

    return (
        <div className='text-center w-100'>
            <DNA
                visible={true}
                height="70"
                width="70"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
            />
        </div>
    );
   
};