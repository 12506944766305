import "../stylesheets/home.scss";
import "../stylesheets/plato.scss";
import PlatoContenidoVideo from "../components/PlatoContenidoVideo";
import PlatoContenidoBtns from "../components/PlatoContenidoBtns";
import React from "react";
import { CONTENT_VIEWED_URL } from "../constants/api-urls";

function PlatoContenido({id, fileType, documentUrl, infographyUrl, type}) {
  function handleViewed(){
    fetch(CONTENT_VIEWED_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({digital_entity_id: id}),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network error.");
      }
    })
    .catch((err) => {
      console.log("Connection error: ", err);
    });
  }
  if (!fileType && !infographyUrl && documentUrl) {
    return <PlatoContenidoVideo url={documentUrl} handleViewed={handleViewed} />
  }
  return <PlatoContenidoBtns documentUrl={documentUrl} infographyUrl={infographyUrl} type={type} handleViewed={handleViewed}/>
}

export default PlatoContenido;
