import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../stylesheets/cambiarclave.scss";
import { CHANGE_PASSWORD_URL } from "../constants/api-urls";

function CambiarClave({ show, onClose }) {
  const [modalShow, setModalShow] = useState(show);

  useEffect(() => {
    setModalShow(show);
  }, [show]);

  const handleClose = () => {
    setModalShow(false);
    onClose(); // Call the onClose function provided by the parent
  };

  function handleSubmit() {
    fetch(CHANGE_PASSWORD_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (!response.ok) throw new Error("Network error.");
    })
    .catch((err) => {
      console.log("Connection error: ", err);
    });

    handleClose();
  }

  return (
    <>
      <Modal show={modalShow} onHide={handleClose}>
        <div className="show-question">
          <div className="icon-question">?</div>
        </div>
        <Modal.Body className="text-info-changepassword">
          Se enviará un correo electrónico a la dirección de tu usuario con las
          instrucciones y el enlace para reestablecer tu clave.
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-cancel"
            variant="secondary"
            onClick={handleClose}
          >
            Descartar
          </Button>
          <Button className="btn-send" variant="primary" onClick={handleSubmit}>
            Enviar enlace por correo
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default CambiarClave;
